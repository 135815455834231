import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { useHistory } from 'react-router-dom';
import Loader from '../Loader';

const Page = ({
  isLoading,
  title,
  subtitle,
  actions,
  backButton = false,
  className,
  children,
}) => {
  const history = useHistory();

  return (
    <section className={`page ${className}`}>
      {(isLoading && (
        <div className="page__loader">
          <Loader size={100} />
        </div>
      )) || (
        <>
          <div className="page__header">
            {backButton && (
              <div className="page__header__back-button">
                <Button
                  theme="square"
                  testSelector="page_back_btn"
                  onClick={() => history.goBack()}>
                  <Icon icon="left" />
                </Button>
              </div>
            )}
            {title && <div className="page__header__title">{title}</div>}
            {actions && <div className="page__header__actions">{actions}</div>}
            {subtitle && (
              <div className="page__header__subtitle">{subtitle}</div>
            )}
          </div>
          <div className="page__content">{children}</div>
        </>
      )}
    </section>
  );
};

Page.propTypes = {
  /** Boolean to render loading screen */
  isLoading: PropTypes.bool.isRequired,
  /** Page title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Page subtitle */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** React components that will display in the actions area */
  actions: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /** Boolean to display back button */
  backButton: PropTypes.bool,
  /** Custom class name, it is usually the same as the page title
   * and matches a scss file and css class.
   * `pageTitle.scss` `page-title {}`
   */
  className: PropTypes.string,
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
  ]).isRequired,
};

export default Page;
