import React, { useState } from 'react';
import moment from 'moment';
import { Accordion, Card, Table } from 'react-bootstrap';
import locationsActions from 'store/actions/Locations';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import CardToggle from 'components/common/CardToggle';
import EditTiming from 'components/views/Locations/TimingModal';
import authService from 'services/auth/service';
import { useDispatch } from 'react-redux';

const renderTiming = (timings, companyIsExempt) => {
  if (companyIsExempt) {
    return '12:00 AM - 11:59 PM';
  }

  if (timings[0].closed) {
    return 'Closed';
  }

  return timings.map((data, index) => (
    <>
      <Icon icon="clock-o" />
      {moment()
        .set('hours', data.open_timing.split(':')[0])
        .set('minutes', data.open_timing.split(':')[1])
        .format('hh:mm A')}{' '}
      -{' '}
      {moment()
        .set('hours', data.close_timing.split(':')[0])
        .set('minutes', data.close_timing.split(':')[1])
        .format('hh:mm A')}
      {index !== timings.length - 1 ? <br /> : null}
    </>
  ));
};

const Timing = ({ timings, locationId }) => {
  const dispatch = useDispatch();
  const [showModalTiming, setShowModalTiming] = useState(false);

  const getTiming = id => dispatch(locationsActions.getTiming(id));

  const openModalTiming = id => {
    setShowModalTiming(true);
    getTiming(id);
  };

  const companyIsExempt = timings[0].exemptions.includes(
    Number(authService.getCompany()),
  );
  const groupedTimings = timings.reduce((acc, curr) => {
    if (!acc[curr.day]) {
      acc[curr.day] = [];
    }

    acc[curr.day].push(curr);
    return acc;
  }, {});

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header as="h4">
          Timing
          <CardToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table className="table--hover">
              <thead>
                <tr>
                  <th>Days</th>
                  <th className="text-center">Hours</th>
                </tr>
              </thead>
              <tbody>
                {timings !== null || timings !== undefined
                  ? Object.keys(groupedTimings).map((day, index) => (
                    <tr key={index}>
                      {Number(day) === 0 ? <td>Monday</td> : null}
                      {Number(day) === 1 ? <td>Tuesday</td> : null}
                      {Number(day) === 2 ? <td>Wednesday</td> : null}
                      {Number(day) === 3 ? <td>Thursday </td> : null}
                      {Number(day) === 4 ? <td>Friday</td> : null}
                      {Number(day) === 5 ? <td>Saturday</td> : null}
                      {Number(day) === 6 ? <td>Sunday</td> : null}
                      <td className="text-center small">
                        {renderTiming(groupedTimings[day], companyIsExempt)}
                      </td>
                    </tr>
                  ))
                  : null}
              </tbody>
            </Table>
            <div style={{ paddingBottom: '1em' }}>
              <Button
                className="float-end"
                onClick={() => openModalTiming(locationId)}
                theme="small"
                colour="white"
              >
                <Icon icon="edit" />
              </Button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <EditTiming openModal={showModalTiming} closeModal={() => {
        setShowModalTiming(false);
      }} />
    </Accordion >
  );
};

export default Timing;
