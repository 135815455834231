import React, { useState, useMemo } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { useSendDriverMessage } from 'api/v2/drivers';
import { useNotificationTemplates } from 'api/v2/notification-templates';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

const SendNotificationModal = ({ isOpen, onClose, order }) => {
  const [messageGroups, setMessageGroups] = useState([
    { template: 'Other', message: '' },
  ]);
  const [sendSms, setSendSms] = useState(false);

  const { mutate: sendDriverMessage } = useSendDriverMessage();
  const { data: templatesData } = useNotificationTemplates();

  const templates = useMemo(
    () =>
      templatesData
        ? [
            ...templatesData.map(t => ({
              value: t.template,
              label: t.name,
            })),
            { value: 'Other', label: 'Other' },
          ]
        : [{ value: 'Other', label: 'Other' }],
    [templatesData],
  );

  const handleClose = () => {
    setMessageGroups([{ template: 'Other', message: '' }]);
    setSendSms(false);
    onClose();
  };

  const handleSend = () => {
    const message = messageGroups.map(m => m.message).join('\n');
    sendDriverMessage({
      driversId: [order.driver_id],
      message,
      sendSms,
    });
    handleClose();
  };

  const handleTemplateChange = (index, selectedTemplate) => {
    const newMessageGroups = [...messageGroups];
    newMessageGroups[index].template = selectedTemplate.value;
    newMessageGroups[index].message =
      selectedTemplate.value === 'Other' ? '' : selectedTemplate.value;
    setMessageGroups(newMessageGroups);
  };

  const handleMessageChange = (index, value) => {
    const newMessageGroups = [...messageGroups];
    newMessageGroups[index].message = value;
    setMessageGroups(newMessageGroups);
  };

  const addMessageGroup = () => {
    setMessageGroups([...messageGroups, { template: 'Other', message: '' }]);
  };

  const removeMessageGroup = index => {
    const newMessageGroups = messageGroups.filter((_, i) => i !== index);
    setMessageGroups(newMessageGroups);
  };

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {messageGroups.map((group, index) => (
            <Row
              key={`message-group-${index}`}
              className="form-group has-feedback">
              <Col lg={11} md={11} sm={11}>
                <Select
                  label="Message Template"
                  options={templates}
                  value={templates.find(t => t.value === group.template)}
                  onChange={selectedTemplate =>
                    handleTemplateChange(index, selectedTemplate)
                  }
                />
                <Input
                  label="Message"
                  type="textarea"
                  rows={4}
                  value={group.message}
                  onChange={e => handleMessageChange(index, e.target.value)}
                  disabled={group.template !== 'Other'}
                />
              </Col>
              <Col lg={1} md={1} sm={1}>
                {!!index && (
                  <Button
                    colour="red"
                    onClick={() => removeMessageGroup(index)}>
                    <Icon icon="minus" />
                  </Button>
                )}
              </Col>
            </Row>
          ))}
          <Row className="form-group has-feedback">
            <Col>
              <Button
                theme="small"
                onClick={addMessageGroup}
                disabled={messageGroups.length >= 10}>
                Add Message &nbsp;
                <Icon icon="plus" />
              </Button>
            </Col>
          </Row>
          <Row className="form-group has-feedback">
            <Col>
              <Input
                type="checkbox"
                label="Send SMS"
                checked={sendSms}
                onChange={e => setSendSms(e.target.checked)}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSend}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendNotificationModal;
