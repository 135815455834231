import React from 'react';

import { authorize } from 'components/common/Authorize';
import Link from 'components/common/Link';
import ToolTip from 'components/shared/ToolTip';
import Badge from 'components/shared/Badge';
import Icon from 'components/shared/Icon';
import { findLongestWord } from 'utils/stringFunctions';
import alerts from 'utils/alerts';
import statuses from 'utils/statuses';
import authService from 'services/auth/service';
import { orderStatus } from 'components/globalInfo/orderStatus';

import { approvals } from '../helpers';

export const renderOrderId = order => (
  <span>
    #
    {authorize({
      abilityPermissions: [{ resource: 'Orders', permissions: ['read'] }],
    }) ? (
      <Link id={order.order_id} to="order-details" target="_blank">
        {order.order_id}
      </Link>
    ) : (
      order.order_id
    )}
  </span>
);

export const renderJobName = order => (
  <ToolTip
    title={findLongestWord(order.job_name) > 10 ? order.job_name : ''}
    placement="bottom">
    {`${
      findLongestWord(order.job_name) > 10
        ? order.job_name.substring(0, 10)
        : order.job_name
    }`}
    {findLongestWord(order.job_name) > 10 && '...'}
  </ToolTip>
);

export const renderCustomerName = order => (
  <ToolTip
    title={findLongestWord(order.customer_name) > 10 ? order.customer_name : ''}
    placement="bottom">
    {`${
      findLongestWord(order.customer_name) > 10
        ? order.customer_name.substring(0, 10)
        : order.customer_name
    }`}
    {findLongestWord(order.customer_name) > 10 && '...'}
  </ToolTip>
);

export const renderCarrierName = order => (
  <ToolTip
    title={findLongestWord(order.carrier_name) > 10 ? order.carrier_name : ''}
    placement="bottom">
    {`${
      findLongestWord(order.carrier_name) > 10
        ? order.carrier_name.substring(0, 10)
        : order.carrier_name
    }`}
    {findLongestWord(order.carrier_name) > 10 && '...'}
  </ToolTip>
);

export const renderDeliveryPrice = order => {
  const { orderPriceBreakdown } = order;
  if (orderPriceBreakdown?.length) {
    const deliveryPrice = orderPriceBreakdown.find(opb => opb.type === 1);
    return `$${deliveryPrice.price}`;
  }
  return `$${order.delivery_price}`;
};

export const renderAlerts = order => (
  <>
    {order.alerts.map(
      alert =>
        alerts[alert] && (
          <ToolTip
            placement="left"
            key={`${order.order_id}_${alert}`}
            title={
              <div key={alert}>
                {alerts[alert].badge}:
                <strong>
                  {alert === 4096
                    ? order.fieldPro_alerts
                        .map(item => alerts[alert].reason[item])
                        .join(', ')
                    : alerts[alert].reason}
                </strong>
              </div>
            }>
            <Badge
              key={`${order.order_id}_${alert}`}
              type={alerts[alert].color ? alerts[alert].color : ''}>
              {alerts[alert].badge}
            </Badge>
          </ToolTip>
        ),
    )}
  </>
);

export const renderStatus = order => (
  <div className="reconcile__status">
    <Badge
      className={`reconcile__status__badge badge badge--status-${
        order.review_status
      }`}>
      {statuses[order.review_status]}
    </Badge>
    <span className="reconcile__status__tooltip">
      <ToolTip
        title={
          <div className="audit-status__tooltip">
            <span className="audit-status__tooltip__text">AUDIT REQUIRED</span>
            {approvals(order).map(item => (
              <div
                key={`tooltip-${item.id}`}
                className="audit-status__tooltip__list">
                <div
                  className={`audit-status__circle ${
                    item.pending ? 'audit-status__circle--pending' : ''
                  } ${
                    item.approved ? 'audit-status__circle--approved' : ''
                  } audit-status__circle--tooltip`}
                />
                <span className="audit-status__tooltip__text">
                  {`${item.title}: `}
                  <span className="audit-status__tooltip__text--bold">
                    {item.status}
                  </span>
                </span>
              </div>
            ))}
          </div>
        }
        placement="left">
        <div className="audit-status">
          {approvals(order).map(item => (
            <div
              key={`approval-${item.id}`}
              className={`audit-status__circle ${
                item.pending ? 'audit-status__circle--pending' : ''
              } ${item.approved ? 'audit-status__circle--approved' : ''}`}
            />
          ))}
        </div>
      </ToolTip>
    </span>
  </div>
);

export const renderActiveStatus = order => {
  const statusInfo = orderStatus?.[String(order.status)] ?? {};
  return <Badge type={statusInfo.class}>{statusInfo.name}</Badge>;
};

export const renderBillingStatus = order => {
  const user = authService.getUser();

  if (
    order.billing_status < 20 &&
    order.billing.approvals.includes(user.currentRole.roleId)
  ) {
    return <Badge className="badge badge--green">Approved</Badge>;
  }

  return (
    <Badge
      className={`badge badge--status-${
        order.billing_status >= 20 ? order.billing_status : order.review_status
      }`}>
      {
        statuses[
          order.billing_status >= 20
            ? order.billing_status
            : order.review_status
        ]
      }
    </Badge>
  );
};

export const renderNotarization = order => {
  if (order.notarization_successful) {
    return (
      <span style={{ display: 'flex', width: 100, justifyContent: 'center' }}>
        <Icon icon="check" colour="green" css={{ alignSelf: 'center' }} />
      </span>
    );
  }
  return (
    <span style={{ display: 'flex', width: 100, justifyContent: 'center' }}>
      <Icon icon="times" colour="red" />
    </span>
  );
};
