const serverSideNaming = {
  order_id: 'orderId',
  job_id: 'jobId',
  customer_id: 'customerId',
  customer_job_id: 'customerJobId',
  id: 'carrierId',
  driver_id: 'driverId',
  'from-completed_at': 'startDate',
  'to-completed_at': 'endDate',
  billing_status: 'billingStatuses',
  review_status: 'reviewStatuses',
  alerts: 'alerts',
  demurrage: 'withDemurrage',
  approvedBy: 'approvedBy',
  districtIds: 'districtIds',
};
const approvalList = [
  { id: 6, title: 'Manager' },
  { id: 3, title: 'Carrier' },
  { id: 2, title: 'Customer' },
];

/**
 *
 * @param {*} order
 * @returns
 */
export const approvals = order =>
  approvalList.map(approvalId => {
    let tempStatus = '';
    const approved = order.billing.approvals.some(
      someElement => someElement === approvalId.id,
    );
    const pending = approved
      ? false
      : order.billing.approvalsRequired.some(sData => sData === approvalId.id);

    if (approved) {
      tempStatus = 'Approved';
    } else if (pending) {
      tempStatus = 'Pending Approval';
    } else {
      tempStatus = 'Not Required';
    }

    return {
      id: approvalId.id,
      status: tempStatus,
      title: approvalId.title,
      pending,
      approved,
    };
  });

export const convertReconcileFilters = filterData =>
  filterData.reduce((obj, { key, value }) => {
    if (key === 'billing_status') {
      obj[serverSideNaming[key]] = value.filter(item => item >= 20);
      obj.reviewStatuses = value.filter(item => item < 20);
    } else if (key === 'demurrage') {
      obj[serverSideNaming[key]] = value === 2 ? 0 : 1;
    } else {
      obj[serverSideNaming[key]] = value;
    }
    if (obj.reviewStatuses && obj.reviewStatuses.length) {
      if (!obj || !obj.billingStatuses || !obj.billingStatuses.length) {
        obj.billingStatuses = [0];
      }
    }
    if (obj.orderId) {
      obj.billingStatuses = [];
      obj.reviewStatuses = [];
    }
    if (
      obj.jobId ||
      obj.carrierId ||
      obj.customerId ||
      obj.driverId ||
      obj.startDate ||
      obj.endDate
    ) {
      obj.billingStatuses = obj.billingStatuses || [];
    }
    if (obj.jobId && obj.jobId.length > 1) {
      obj.jobIds = obj.jobId;
      obj.jobId = null;
    }
    if (obj.carrierId && obj.carrierId.length) {
      obj.carrierIds = obj.carrierId;
      obj.carrierId = null;
    }
    if (
      obj.billingStatuses &&
      obj.billingStatuses.length &&
      !obj.billingStatuses.filter(d => d === 0).length
    ) {
      obj.mergeBillingAndReviewFilters = true;
    }
    return obj;
  }, {});

export const genericSorting = (data, { key, isAscending }) => {
  if (data?.collection) {
    data = data.collection;
  }
  const sortedData = data.sort((a, b) => (a[key] < b[key] ? 1 : -1));

  if (isAscending) {
    return sortedData.reverse();
  }

  return sortedData;
};
