import moment from 'moment';

/**
 *
 * @param {moment.MomentInput} date
 * @param {'subtract' | 'add'} action
 * @param {moment.DurationInputArg1} days
 * @returns
 */
export const modifyDueDate = (date, action, days) => {
  const modifiedDate = moment(date);
  if (action === 'subtract') {
    return modifiedDate.subtract(days, 'days');
  }
  return modifiedDate.add(days, 'days');
};
