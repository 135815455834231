import { client } from 'api/client';
import FileSaver from 'file-saver';

export const getOrders = async ({ params, page, pageSize }) => {
  const { data } = await client.post('v2/order/search', {
    ...params,
    page,
    itemsPerPage: pageSize,
  });
  return data.data;
};

/**
 * @typedef {{
 *   info: any;
 *   commodityInfo: any;
 *   details: any;
 *   sandTicketIds: any[];
 *   transfers: any[];
 * }} OrderResponse
 */

/**
 *
 * @param {number} orderId
 * @returns {Promise<OrderResponse>}
 */
export const getOrder = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}`);
  return data.data;
};

/**
 * @typedef {{
 *  purchaseOrderId: number;
 *  originId: number;
 *  destinationId: number;
 *  sandTypeId: number;
 *  stage: string;
 *  unloadAppointment: string;
 *  loadAppointment: string;
 *  customerId: number;
 *  autoAssign: boolean;
 *  driverId: number;
 *  manualDispatchEnabled: boolean;
 *  stagingSite?: number;
 * }} AddOrderInfo
 */

/**
 *
 * @param {{
 *  jobId: number;
 *  orderInfo: AddOrderInfo
 * }} params
 */
export const addOrder = async ({ jobId, orderInfo }) => {
  const { data } = await client.post(`v2/order`, {
    jobId,
    ...orderInfo,
  });
  return data;
};

export const modifyOrder = async ({ orderId, orderInfo }) => {
  const { data } = await client.put(`/v2/order/${orderId}`, orderInfo);
  return data;
};

export const getOrderContainers = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/containers`);
  return data.data.containers;
};

export const getOrderBreadcrumbs = async orderId => {
  const { data } = await client.get(`v2/order/${orderId}/bread-crumb`);
  return data.data;
}

/**
 *
 * @param {number} orderId
 */
export const downloadInvoicePdf = async orderId => {
  const { data } = await client.post(
    `v2/order/${orderId}/invoice.pdf`,
    {},
    {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'arraybuffer',
    },
  );
  const file = new Blob([data], { type: 'application/pdf' });
  FileSaver.saveAs(file, `order-${orderId}.pdf`);
};

export const getJobsForDiversion = async params => {
  const { data } = await client.get(
    `v2/order/${params.orderId}/jobs-for-diversion`,
    { params },
  );
  return data.data.jobs;
};

/**
 * @param {{
 *  orderId: number;
 * }} data
 */
export const divert = async data => {
  const response = await client.put(
    `v2/order/${data.orderId}/divert-order`,
    data,
  );
  return response.data.data;
};

/**
 *
 * @param {number} driverId
 * @param {number} customerId
 * @param {number} orderId
 * @returns {Promise<{isMissing: boolean}>}
 */
export const getDriverHasContainer = async (orderId, driverId, customerId) => {
  const { data } = await client.get(`v2/order/${orderId}/driver/${driverId}/customer/${customerId}/driver-container-check`);
  return data.data;
};

/**
 * @param {number} orderId
 * @param {number} driverId
 */
export const callToDestination = async (orderId, driverId) => {
  const { data } = await client.post('/v2/driver/call-to-well', {
    orderId: orderId,
    driverId: driverId,
  });

  return data;
}
