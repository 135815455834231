import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  createNotificationTemplate,
  getNotificationTemplates,
  updateNotificationTemplate,
  deleteNotificationTemplate,
} from './queries';

export const useCreateNotificationTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: data => createNotificationTemplate(data),
    mutationKey: ['notification-templates'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-templates'] });
      swal('Success', 'Notification template created successfully', 'success');
    },
    onError: error => {
      swal('Error', error.response.data.data.message, 'error');
    },
  });
};

export const useNotificationTemplates = () =>
  useQuery({
    queryKey: ['notification-templates'],
    queryFn: getNotificationTemplates,
  });

export const useUpdateNotificationTemplate = id => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: template => updateNotificationTemplate({ ...template, id }),
    mutationKey: ['notification-templates'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-templates'] });
      swal('Success', 'Notification template updated successfully', 'success');
    },
    onError: error => {
      swal('Error', error.response.data.data.message, 'error');
    },
  });
};

export const useDeleteNotificationTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteNotificationTemplate,
    mutationKey: ['notification-templates'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notification-templates'] });
      swal('Success', 'Notification template deleted successfully', 'success');
    },
    onError: error => {
      swal('Error', error.response.data.data.message, 'error');
    },
  });
};
