import React, { useEffect, useState } from 'react';

import Select from '../Select';

const PaginationSelect = ({
  currentPage,
  totalPages,
  onSelect = () => {},
  isDisabled,
}) => {
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(currentPage);

  useEffect(() => {
    const pages = Array(totalPages)
      .fill()
      .map((__, index) => ({
        value: index + 1,
        label: String(index + 1),
      }));

    setPageOptions(pages);
  }, [totalPages]);

  useEffect(() => {
    setSelectedPage(currentPage);
  }, [currentPage]);

  return (
    <Select
      value={pageOptions.find(({ value }) => value === selectedPage)}
      options={pageOptions}
      onChange={onSelect}
      placeholder="Page"
      testSelector="pagination_select_options_select"
      isDisabled={isDisabled}
    />
  );
};

export default PaginationSelect;
