import React, { useState, useEffect, useMemo } from 'react';

import { useOrders } from 'api/v2/orders';
import TableList from 'components/shared/TableList';
import Loader from 'components/shared/Loader';
import Pagination from 'components/shared/Pagination';

import ReconcileSlidePanel from '../ReconcileSlidePanel';
import { formatOrderFilters } from './helpers';
import { genericSorting } from '../helpers';
import {
  renderActiveStatus,
  renderCarrierName,
  renderCustomerName,
  renderJobName,
  renderNotarization,
  renderOrderId,
} from '../ListComponents';

const columns = [
  { key: 'order_id', value: 'ORDER' },
  { key: 'customer_name', value: 'CUSTOMER' },
  { key: 'job_name', value: 'JOB' },
  { key: 'carrier_name', value: 'CARRIER' },
  { key: 'driver_name', value: 'DRIVER' },
  { key: 'districts', value: 'DISTRICTS' },
  { key: 'status', value: 'STATUS' },
  { key: 'notarization', value: 'NOTARIZATION' },
];

const ActiveOrderList = ({ filters }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [ordering, setOrdering] = useState({
    key: 'order_id',
    isAscending: true,
  });

  useEffect(() => {
    setPagination({ page: 1, pageSize: 10 });
  }, [filters]);

  const { data: orderData, isLoading, isPlaceholderData } = useOrders({
    params: {
      ...formatOrderFilters(filters),
      statuses: [2, 3, 5, 6, 7, 8],
    },
    page: pagination.page,
    pageSize: pagination.pageSize,
  });
  const orders = useMemo(
    () => genericSorting(orderData?.orders || [], ordering),
    [orderData, ordering],
  );

  const handlePageChange = ({ currentPage }) =>
    setPagination({ ...pagination, page: currentPage });
  const handleLimitChange = pageSize =>
    setPagination({ ...pagination, page: 1, pageSize });

  const handleSort = key => {
    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const builtItems = orders.map(order => ({
    key: order.order_id,
    isSelected: selectedOrder && selectedOrder.order_id === order.order_id,
    originalData: order,
    onRowClick: () => setSelectedOrder(order),
    data: {
      order_id: () => renderOrderId(order),
      customer_name: () => renderCustomerName(order),
      job_name: () => renderJobName(order),
      carrier_name: () => renderCarrierName(order),
      driver_name: () => order.driver_name,
      districts: () => order.districts?.map(d => d.name)?.join(', '),
      status: () => renderActiveStatus(order),
      notarization: () => renderNotarization(order),
    },
  }));

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <TableList
          onSort={handleSort}
          clickableTable
          columns={columns}
          listItems={builtItems}
        />
      )}
      {!!orders?.length && (
        <Pagination
          currentPage={pagination.page}
          totalPages={orderData?.totalPages}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          currentLimit={pagination.pageSize}
          withLimit
          isDisabled={isPlaceholderData}
        />
      )}

      <ReconcileSlidePanel
        isVisible={!!selectedOrder}
        closePanel={() => setSelectedOrder(null)}
        page="orderStatus"
        selectedOrder={selectedOrder}
      />
    </div>
  );
};

export default ActiveOrderList;
