import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import locationsActions from 'store/actions/Locations';
import QRTemplate from 'components/views/Locations/QRTemplate';
import Button from 'components/shared/Button';
import EditLocation from 'components/views/Locations/AddLocationModal';
import { useDispatch } from 'react-redux';

const SandSiteInfo = ({ sandSite, totalOrders, averageTimeLoad }) => {
  const dispatch = useDispatch();

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalQRTemplate, setShowModalQRTemplate] = useState(false);

  const initEditQr = id => dispatch(locationsActions.getQRTemplate(id));
  const setLocation = location => dispatch(locationsActions.setLocation(location));

  const openEdit = location => {
    setLocation({
      ...location,
      loading_site_special_instruction: location.loadingSiteSpecialInstructions,
      contact_no: location.contactNumber,
      suspended: location.status,
    });
    setShowModalEdit(true);
  };

  const openModalQRTemplate = location => {
    initEditQr(location);
    setShowModalQRTemplate(true);
  };

  return (
    <Card>
      <Card.Body>
        <h2 style={{ display: 'inline' }}>
          #{sandSite.id}
          <strong className="text-navy"> {sandSite.name}</strong>
          <Badge
            bg="none"
            className="badge-info float-end"
            title="Average loading time for the last 24 hours before the last loaded order">
            Average loading time: {Math.round(averageTimeLoad) || 0} min
          </Badge>
          <Badge bg="none" className="badge-primary float-end">
            Completed orders: {totalOrders}
          </Badge>
          &nbsp;
          <Button
            key="edit"
            onClick={() => openEdit(sandSite)}
            theme="small"
            className="badge-info"
            testSelector={`locations_list_edit_${sandSite.id}_btn`}>
            <Icon icon="pencil" />
          </Button>
          <Button
            key="qr_template"
            className="badge-info"
            onClick={() => openModalQRTemplate(sandSite.id)}
            theme="small"
          >
            QR Template
          </Button>
        </h2>
        {showModalQRTemplate && (
          <QRTemplate
            open={showModalQRTemplate}
            onClose={() => setShowModalQRTemplate(false)}
            selectedLocation={sandSite.id}
          />
        )}
        <EditLocation
          openModal={showModalEdit}
          closeModal={() => setShowModalEdit(false)}
          action="edit"
        />

        <Row>
          <Col lg={4}>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Icon icon="envelope" /> Email:&nbsp;
                    </strong>
                    {sandSite.email || 'N/A'}
                  </td>
                </tr>
                <tr className="border-bottom-table">
                  <td>
                    <strong>
                      <Icon icon="phone" /> Phone:&nbsp;
                    </strong>
                    {sandSite.contactNumber}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={4}>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Icon icon="map-marker" /> State:&nbsp;
                    </strong>
                    {sandSite.state}, {sandSite.city}
                  </td>
                </tr>
                <tr className="border-bottom-table">
                  <td>
                    <strong>Address:&nbsp; </strong>
                    {sandSite.address}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={4}>
            <Table>
              <tbody>
                <tr className="border-bottom-table">
                  <td>
                    <strong>Special instructions:&nbsp; </strong>
                    {sandSite.loadingSiteSpecialInstructions}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card >
  )
};


export default SandSiteInfo;
