import React, { useState, useEffect } from 'react';
import Tabs from 'components/shared/Tabs';
import OrderInfo from './OrderInfo';
import Activity from './Activtiy';

export const SlideOutPanelContext = React.createContext();

const OrderDetails = ({ selectedOrder, page }) => {
  const [currentTab, setCurrentTab] = useState('orderInfo');
  const [currentOrder, setCurrentOrder] = useState(selectedOrder);

  useEffect(() => {
    setCurrentOrder(selectedOrder);
  }, [selectedOrder]);

  const tabItems = [
    {
      defaultTab: currentTab === 'orderInfo',
      label: 'Order Info',
      content: currentOrder && (
        <OrderInfo
          setCurrentTab={setCurrentTab}
          data={currentOrder}
          page={page}
        />
      ),
    },
    {
      defaultTab: currentTab === 'activity',
      label: 'Activity',
      content: currentOrder && (
        <Activity setCurrentTab={setCurrentTab} data={currentOrder} />
      ),
    },
  ];

  return (
    <SlideOutPanelContext.Provider value={{ setCurrentOrder }}>
      <Tabs tabs={tabItems} />
    </SlideOutPanelContext.Provider>
  );
};

export default OrderDetails;
