import React, { useState } from 'react';

import Block from 'components/shared/Block';
import { useDebounce } from 'helpers/hooks';
import ActiveOrderFilters from './ActiveOrderFilters';
import ActiveOrderList from './ActiveOrderList';

const ReconcileActiveOrders = () => {
  const [_filters, setFilters] = useState([]);
  const filters = useDebounce(_filters, 500);

  return (
    <Block theme="none" modifiers={['no-border']}>
      <ActiveOrderFilters
        filters={[
          {
            key: 'notarizationStatus',
            label: 'Status',
            type: 'number',
            value: 0,
          },
        ]}
        applyFilters={setFilters}
      />
      <ActiveOrderList filters={filters} />
    </Block>
  );
};
export default ReconcileActiveOrders;
