import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  approveOrder,
  contestOrder,
  getDisputeReasons,
  getInvoiceTemplates,
} from './queries';

export const useDisputeReasons = () =>
  useQuery({
    queryKey: ['disputeReasons'],
    queryFn: getDisputeReasons,
    placeholderData: [],
  });

export const useInvoiceTemplates = () =>
  useQuery({
    queryKey: ['invoiceTemplates'],
    queryFn: getInvoiceTemplates,
    placeholderData: [],
  });

export const useApproveOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: orderIds => approveOrder({ orderIds }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      swal('Success', 'Order(s) approved successfully!', 'success');
    },
    onError: error => {
      swal('Error', error.response.data.data.message, 'error');
    },
  });
};

export const useContestOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ orderIds, comment, disputeReasonId }) =>
      contestOrder({
        orderIds,
        comment,
        disputeReasonId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      swal('Success', 'Order(s) contested successfully!', 'success');
    },
    onError: error => {
      swal('Error', error.response.data.data.message, 'error');
    },
  });
};
