import React, { useState } from 'react';

import {
  useDeleteNotificationTemplate,
  useNotificationTemplates,
} from 'api/v2/notification-templates';

import { deleteAlert } from 'components/sweetalert';
import Loader from 'components/shared/Loader';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Authorize from 'components/common/Authorize';
import { Row } from 'react-bootstrap';
import AddEditModal from './AddEditModal';

const NotificationTemplates = () => {
  const {
    data: notificationTemplates,
    isLoading,
    isFetching,
  } = useNotificationTemplates();
  const {
    mutate: deleteNotificationTemplate,
  } = useDeleteNotificationTemplate();
  const [showUpdate, turnShowUpdate] = useState(false);
  const [editModalValue, setEditModalValue] = useState({});
  const [status, setStatus] = useState('');

  const isLoaded = !isLoading && !isFetching;

  const openUpdate = (newStatus, value) => {
    setStatus(newStatus);

    if (newStatus === 'edit') {
      setEditModalValue(value);
    }

    turnShowUpdate(true);
  };

  const renderNotificationTemplate = template => (
    <div key={`key${template.id}`} className="tree-node tree-node-content">
      <span className="tree-node-content-name">
        <strong style={{ color: '#ffffff' }}>
          #{template.id} | {template.name}
        </strong>
      </span>
      <Authorize access={['JOB_MANAGER']}>
        <span>
          <Button
            onClick={() => openUpdate('edit', template)}
            theme="small"
            testSelector="notification_templates_edit_btn">
            <Icon icon="pencil" />
          </Button>
        </span>
      </Authorize>
      <Authorize access={['JOB_MANAGER']}>
        <Button
          onClick={() => deleteAlert(deleteNotificationTemplate, template.id)}
          theme="small"
          testSelector="notification_templates_delete_btn">
          <Icon icon="trash" />
        </Button>
      </Authorize>
    </div>
  );

  return (
    <div className="wrapper-content">
      <Row>
        {isLoaded ? (
          <div className="ibox">
            <div className="ibox-content">
              <Authorize access={['JOB_MANAGER']}>
                <Button
                  onClick={() => openUpdate('add')}
                  inverse
                  className="float-end">
                  <Icon className="icon--margin-right" icon="plus-square" />
                  Add Notification Template
                </Button>
              </Authorize>
              {notificationTemplates.map(renderNotificationTemplate)}
            </div>
          </div>
        ) : (
          <div className="ibox">
            <Loader />
          </div>
        )}
      </Row>

      <AddEditModal
        status={status}
        value={editModalValue ?? {}}
        closeUpdate={() => turnShowUpdate(false)}
        showUpdate={showUpdate}
      />
    </div>
  );
};

export default NotificationTemplates;
