import React from 'react';
import SlidePanel from 'components/shared/SlidePanel';
import OrderDetails from './OrderDetails/OrderDetails';

const ReconcileSlidePanel = ({
  isVisible,
  closePanel,
  selectedOrder,
  page,
}) => (
  <SlidePanel isVisible={isVisible} closePanel={closePanel}>
    <OrderDetails
      selectedOrder={selectedOrder}
      page={page}
    />
  </SlidePanel>
);

export default ReconcileSlidePanel;
