import React, { useMemo } from 'react';

import Filter, { FilterInput, FilterSelect } from 'components/shared/Filter';
import authService from 'services/auth/service';
import { useJobs } from 'api/v2/jobs';
import { ROLES } from 'config/constants';
import { useCustomers } from 'api/v2/customers';
import { useCarriers } from 'api/v2/carriers';
import { useDistricts } from 'api/v2/districts';

const ActiveOrderFilters = ({ filters = [], applyFilters = () => {} }) => {
  const user = authService.getUser();
  const { data: jobs } = useJobs();
  const { data: customers } = useCustomers();
  const { data: carriers } = useCarriers();
  const { data: districts } = useDistricts();

  const initialFilters = useMemo(() => (filters.length > 0 ? filters : []), [
    filters,
  ]);

  return (
    <Filter onUpdate={applyFilters} active={initialFilters} filterVisible>
      <FilterInput
        filterLabel="Order Id"
        filterKey="orderId"
        label="Order Id"
        type="number"
        testSelector="reconcile_filters_order-id_input"
      />
      <FilterSelect
        filterLabel="Job"
        filterKey="jobId"
        valueKey="id"
        labelKey="job_name"
        placeholder="Jobs"
        isMulti
        options={jobs ?? []}
      />
      {user.role === ROLES.CUSTOMER ? (
        customers.length && (
          <FilterInput
            disabled
            filterKey="customerId"
            label="Customer"
            value={`${customers[0].name}`}
          />
        )
      ) : (
        <FilterSelect
          filterLabel="Customer"
          filterKey="customerId"
          valueKey="id"
          labelKey="name"
          placeholder="Customer"
          options={customers}
        />
      )}
      {user.role === ROLES.CARRIER ? (
        carriers.length && (
          <FilterInput
            disabled
            filterKey="carrierId"
            label="Carrier"
            value={`${carriers[0].name}`}
          />
        )
      ) : (
        <FilterSelect
          filterLabel="Carrier"
          filterKey="carrierId"
          valueKey="id"
          labelKey="name"
          placeholder="Carriers"
          options={carriers}
          isMulti
        />
      )}
      <FilterSelect
        filterLabel="District"
        filterKey="districtIds"
        valueKey="id"
        labelKey="name"
        placeholder="Districts"
        options={districts}
        isMulti
      />
      <FilterSelect
        filterLabel="Notarization"
        filterKey="notarizationStatus"
        valueKey="value"
        labelKey="label"
        type="number"
        placeholder="Notarization Status"
        options={[
          { value: 0, label: 'Failed' },
          { value: 1, label: 'Succeeded' },
        ]}
      />
    </Filter>
  );
};
export default ActiveOrderFilters;
