import { client } from 'api/client';

export const getDisputeReasons = async () => {
  const { data } = await client.get('v2/lookup/dispute-reasons');
  return data.data.disputeReasons;
};

export const getInvoiceTemplates  = async () => {
  const { data } = await client.get('v2/lookup/templates/invoices/bulk');
  return data.data.invoiceTemplates;
}

/**
 *
 * @param {{
 *  orderIds: number[] | string[];
 * }} params
 * @returns
 */
export const approveOrder = async params => {
  const { data } = await client.post('v2/billing/orders/approve', params);
  return data;
};

/**
 *
 * @param {{
 *  orderIds: number[] | string[];
 *  comment: string;
 *  disputeReasonId: number;
 * }} params
 * @returns
 */
export const contestOrder = async params => {
  const { data } = await client.post('v2/billing/orders/dispute', params);
  return data;
};
