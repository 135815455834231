import React from 'react';

import Authorize from 'components/common/Authorize';
import Page from 'components/shared/Page';
import Tabs from 'components/shared/Tabs';
import Block from 'components/shared/Block';

import ReconcileActionItems from './ReconcileActionItems';
import ReconcileOrderStatus from './ReconcileOrderStatus';
import ReconcileActiveOrders from './ActiveOrders';

const tabs = [
  {
    defaultTab: true,
    label: 'Active Orders',
    content: <ReconcileActiveOrders />,
  },
  {
    label: 'Action Items',
    content: <ReconcileActionItems />,
  },
  {
    label: 'Order Status',
    content: <ReconcileOrderStatus />,
  },
];

const Reconcile = () => (
  <Page title="Reconcile" className="reconcile">
    <Block float modifiers={['no-padding', 'reconcile']}>
      <Authorize
        abilityPermissions={[{ resource: 'Reconcile', permissions: ['read'] }]}>
        <Tabs tabs={tabs} />
      </Authorize>
    </Block>
  </Page>
);
export default Reconcile;
