import { slsClient } from 'api/client';

/**
 *
 * @param {{
 *  orders: number[] | string[];
 *  customerId: number;
 *  dueDate: string;
 *  enableRemitTo: boolean;
 *  groupOrdersBy: string;
 *  templateId: number;
 * }} params
 * @returns
 */
export const createInvoice = async params => {
  const { data } = await slsClient.post('/invoice', params);
  return data;
};
