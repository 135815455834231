import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

import {
  useUpdateNotificationTemplate,
  useCreateNotificationTemplate,
} from 'api/v2/notification-templates';

const AddEditModal = ({ value, status, closeUpdate, showUpdate }) => {
  const { mutate: updateNotificationTemplate } = useUpdateNotificationTemplate(
    value.id,
  );
  const {
    mutate: createNotificationTemplate,
  } = useCreateNotificationTemplate();
  const [name, setName] = useState('');
  const [template, setTemplate] = useState('');

  useEffect(() => {
    if (showUpdate && status === 'edit' && value) {
      setName(value.name);
      setTemplate(value.template || '');
    } else {
      setName('');
      setTemplate('');
    }
  }, [showUpdate, value, status]);

  const selectFunction = {
    add: (e, closeModal) => {
      e.preventDefault();
      createNotificationTemplate(
        {
          name,
          template,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        },
      );
    },
    edit: (e, closeModal) => {
      e.preventDefault();
      updateNotificationTemplate(
        {
          name,
          template,
          id: value.id,
        },
        {
          onSuccess: () => {
            closeModal();
          },
        },
      );
    },
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => selectFunction[status](e, closeUpdate)}>
          <Modal.Header>
            <h3>
              {status === 'add'
                ? 'Add Notification Template'
                : 'Edit Notification Template'}
            </h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setName(targetValue(e))}
                  value={name}
                  label="Name"
                  required
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setTemplate(targetValue(e))}
                  value={template}
                  label="Template"
                  required
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeUpdate} colour="white">
              Close
            </Button>
            <Button
              type="submit"
              disabled={
                !name || !name?.length || !template || !template?.length
              }>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddEditModal;
