import { client } from 'api/client';

export const createNotificationTemplate = async template => {
  const { data } = await client.post('v2/notification-templates', template);
  return data;
};

export const getNotificationTemplates = async () => {
  const { data } = await client.get('v2/notification-templates');
  return data.data;
};

export const updateNotificationTemplate = async template => {
  const { data } = await client.put(
    `v2/notification-templates/${template.id}`,
    template,
  );
  return data;
};

export const deleteNotificationTemplate = async id => {
  const { data } = await client.delete(`v2/notification-templates/${id}`);
  return data;
};
