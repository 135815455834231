import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { useContestOrder, useDisputeReasons } from 'api/v2/billing';
import { jobPlansContestOrder } from 'store/actions/Orders';

import Modal from 'components/shared/Modal';
import Select from 'components/shared/Select';
import { Textarea } from 'components/shared/Input';
import Button from 'components/shared/Button';

const ContestModal = ({
  open,
  onClose,
  onSuccess = () => {},
  orderIds,

  // TODO: Remove these props after the job plans query hooks are implemented
  jobPlansContestOrderFunc,
  jobPlansdata,
}) => {
  const { data: disputeReasons } = useDisputeReasons();
  const { mutate: contestOrder } = useContestOrder();

  const [comment, setComment] = useState('');
  const [currentReason, setCurrentReason] = useState(null);

  const isDisabled = useMemo(() => !currentReason || !comment?.trim().length, [
    currentReason,
    comment,
  ]);

  const handleDispute = () => {
    if (jobPlansdata) {
      // TODO: Remove this condition after the job plans query hooks are implemented
      jobPlansContestOrderFunc(orderIds, comment, currentReason, jobPlansdata);
    } else {
      contestOrder(
        {
          orderIds,
          comment,
          disputeReasonId: currentReason,
        },
        {
          onSuccess,
        },
      );
    }
    onClose();
  };

  const clearState = () => {
    setComment('');
    setCurrentReason(null);
  };

  useEffect(() => {
    if (open) {
      clearState();
    }
  }, [open]);

  const disputeOptions = disputeReasons.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const body = (
    <div className="contest-modal">
      <Select
        onChange={item => setCurrentReason(item.value)}
        options={disputeOptions}
        placeholder="Add reason"
        required
        testSelector="contest-modal_add-reason_select"
      />
      <Textarea
        label="Add Comment"
        name="Add Comment"
        onChange={e => setComment(e.target.value)}
        value={comment}
        required
      />
    </div>
  );

  const footer = (
    <>
      <Button
        colour="white"
        onClick={() => {
          onClose();
          clearState();
        }}
        testSelector="contest-modal_close_btn">
        Close
      </Button>
      <Button
        onClick={handleDispute}
        disabled={isDisabled}
        testSelector="contest-modal_save_btn">
        Save
      </Button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Contest order for Billing"
      body={body}
      footer={footer}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  jobPlansContestOrderFunc: (
    orderIds,
    comments,
    disputeReasonId,
    jobPlansdata,
  ) =>
    dispatch(
      jobPlansContestOrder(orderIds, comments, disputeReasonId, jobPlansdata),
    ),
});

export default connect(
  null,
  mapDispatchToProps,
)(ContestModal);
