import React from 'react';
import PropTypes from 'prop-types';

const Block = ({
  title,
  theme = 'blue',
  float,
  modifiers,
  className,
  children,
}) => (
  <div
    className={`block 
        block--${theme}
        ${float ? 'block--float' : ''}
        ${modifiers &&
          modifiers.length > 0 &&
          `block--${modifiers.reduce(
            (combined, current) => `${combined} block--${current}`,
          )}`}
        ${className || ''}
      `}>
    {title && <div className="block__title">{title}</div>}
    <div className="block__content">{children}</div>
  </div>
);

Block.propTypes = {
  /** Option for drop shadow */
  float: PropTypes.bool,
  /** Controls the background of the Block */
  theme: PropTypes.oneOf(['blue', 'dark', 'none']),
  /** Modifiers Options: 'no-padding', 'margin', 'no-border', 'small-title' */
  modifiers: PropTypes.arrayOf(PropTypes.string),
  /** Title for the block */
  title: PropTypes.string,
  /** Custom className, usual will be the block title */
  className: PropTypes.string,
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

const Break = ({ noMargin }) => (
  <hr
    className={`block__block-break 
    ${noMargin ? 'block__block-break--no-margin' : ''}`}
  />
);

Break.propTypes = {
  /** Forces Break to span whole block */
  noMargin: PropTypes.bool,
};

export default Block;
export { Break };
