import React, { useState } from 'react';
import moment from 'moment';

import Block from 'components/shared/Block';

import ReconcileBaseFilter from '../ReconcileBaseFilter';
import ReconcileActionItemsList from './ReconcileActionItemsList';

const baseFilters = {
  statuses: [4],
  excludeCustomerOperatedOrders: true,
  isInReconcile: true,
  requiredReview: true,
  sortBy: 'order_id DESC',
  page: 1,
  itemsPerPage: 10,
  billingStatuses: [0],
  startDate: moment()
    .subtract(3, 'M')
    .startOf('day')
    .utc()
    .format(),
  endDate: moment()
    .endOf('day')
    .utc()
    .format(),
};

const ReconcileActionItems = () => {
  const [requestFilters, _setRequestFilters] = useState(baseFilters);

  const setRequestFilters = newRequestFilters => {
    _setRequestFilters({
      ...baseFilters,
      ...newRequestFilters,
    });
  };

  return (
    <Block theme="none" modifiers={['no-border']}>
      <ReconcileBaseFilter
        view="action_items"
        applyFilters={setRequestFilters}
        defaultFilters={[
          {
            key: 'from-completed_at',
            label: 'Completed From',
            onFilter: null,
            type: 'range',
            value: moment()
              .subtract(3, 'M')
              .startOf('day'),
          },
          {
            key: 'to-completed_at',
            label: 'Completed To',
            onFilter: null,
            type: 'range',
            value: moment().endOf('day'),
          },
        ]}
      />
      <ReconcileActionItemsList filters={requestFilters} />
    </Block>
  );
};

export default ReconcileActionItems;
