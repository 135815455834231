import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';

import { useEquipment } from 'api/v2/equipment';
import { useApproveOrder } from 'api/v2/billing';
import { convertTime } from 'utils/convertTime';
import statuses from 'utils/statuses';
import authService from 'services/auth/service';

import Authorize from 'components/common/Authorize';
import TableList from 'components/shared/TableList';
import DropdownButton from 'components/shared/DropdownButton';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import { confirmAlert } from 'components/sweetalert';
import ContestModal from 'components/shared/ContestModal';
import Pagination from 'components/shared/Pagination';

import { useOrders } from 'api/v2/orders';
import Loader from 'components/shared/Loader';
import CreateInvoiceModal from './CreateInvoiceModal';
import ReconcileSlidePanel from '../ReconcileSlidePanel';
import { genericSorting } from '../helpers';
import {
  renderOrderId,
  renderBillingStatus,
  renderCarrierName,
  renderCustomerName,
  renderDeliveryPrice,
  renderJobName,
} from '../ListComponents';

const columns = [
  { key: 'order_id', value: 'ORDER' },
  { key: 'customer_name', value: 'CUSTOMER' },
  { key: 'customer_job_id', value: 'CUSTOMER JOB ID' },
  { key: 'job_name', value: 'JOB' },
  { key: 'carrier_name', value: 'CARRIER' },
  { key: 'driver_name', value: 'DRIVER' },
  { key: 'completed_at', value: 'COMPLETED' },
  { key: 'delivery_price', value: 'DELIVERY' },
  { key: 'additional_price', value: 'ADDITIONAL' },
  { key: 'totalPrice', value: 'TOTAL' },
  { key: 'billing_status', value: 'STATUS' },
];

const ReconcileOrderStatusList = ({ filters }) => {
  const user = authService.getUser();
  const { data: equipmentRequired } = useEquipment();
  const { mutate: approveOrder } = useApproveOrder();

  const [resetCheckboxes, setResetCheckboxes] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [contestModal, setContestModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const orderIds = checkedOrders.map(({ key }) => key);

  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [ordering, setOrdering] = useState({
    key: 'order_id',
    isAscending: false,
  });

  useEffect(() => {
    setPagination({ page: 1, pageSize: 10 });
  }, [filters]);

  const { data: orderData, isLoading, isPlaceholderData } = useOrders({
    params: filters,
    page: pagination.page,
    pageSize: pagination.pageSize,
  });
  const orders = useMemo(
    () => genericSorting(orderData?.orders || [], ordering),
    [orderData, ordering],
  );

  const handlePageChange = ({ currentPage }) =>
    setPagination({ ...pagination, page: currentPage });
  const handleLimitChange = pageSize =>
    setPagination({ ...pagination, page: 1, pageSize });
  const handleCheck = rows => {
    setResetCheckboxes(false);
    setCheckedOrders(rows);
  };
  const handleTableReset = () => {
    setResetCheckboxes(true);
    setCheckedOrders([]);
  };

  const handleSort = key => {
    if (ordering.key === key) {
      return setOrdering({ ...ordering, isAscending: !ordering.isAscending });
    }

    return setOrdering({ key, isAscending: false });
  };

  const approveContest = param => {
    swal.close();
    setContestModal(param);
  };

  const handleApprove = ids => {
    approveOrder(ids, {
      onSuccess: handleTableReset,
    });
  };

  const actionOptions = [
    {
      name: 'approve',
      label: 'Approve',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(handleApprove, orderIds, {
            title: `Are you sure you want to approve these ${
              orderIds.length
            } orders?`,
            text: ' ',
          });
        } else {
          handleApprove(orderIds);
        }
      },
    },
    {
      name: 'contest',
      label: 'Contest',
      onClick: () => {
        if (orderIds.length > 1) {
          confirmAlert(approveContest, true, {
            title: `Are you sure you want to contest these ${
              orderIds.length
            } orders?`,
            text: ' ',
          });
        } else {
          approveContest(true);
        }
      },
    },
    {
      name: 'create invoice',
      label: 'Create Invoice',
      onClick: () => setCreateInvoiceModal(true),
    },
  ];

  // const exportOptions = [
  //   {
  //     name: 'excel',
  //     label: 'Excel Workbook',
  //     onClick: () => console.log('Excel Workbook'),
  //     dropdownIcon: 'excel',
  //   },
  //   {
  //     name: 'csv',
  //     label: 'CSV File',
  //     onClick: () => console.log('CSV File'),
  //     dropdownIcon: 'csv',
  //   },
  //   {
  //     name: 'pdf',
  //     label: 'PDF Document',
  //     onClick: () => console.log('PDF Document'),
  //     dropdownIcon: 'pdf',
  //   },
  // ];

  const csvHeaders = columns
    .concat([{ key: 'formatted_status', value: 'STATUS DESC' }])
    .map(c => {
      c.label = c.value;
      return c;
    });

  const csvOrders = orders?.length
    ? _.cloneDeep(orders).map(o => {
        if (
          o.billing_status < 20 &&
          o.billing.approvals.includes(user.currentRole.roleId)
        ) {
          o.formatted_status = 'Approved';
        } else {
          o.formatted_status =
            statuses[
              o.billing_status >= 20 ? o.billing_status : o.review_status
            ];
        }
        o.completed_at = convertTime(o.completed_at);
        o.customer_job_id = o.customer_job_id || o.po_reference;
        return o;
      })
    : [];

  const builtItems = orders.map(order => ({
    key: order.order_id,
    isSelected: selectedOrder && selectedOrder.order_id === order.order_id,
    originalData: order,
    onRowClick: () => setSelectedOrder(order),
    data: {
      order_id: () => renderOrderId(order),
      job_name: () => renderJobName(order),
      customer_name: () => renderCustomerName(order),
      customer_job_id: () => order.customer_job_id || order.po_reference,
      carrier_name: () => renderCarrierName(order),
      driver_name: () => order.driver_name,
      completed_at: () => convertTime(order.completed_at),
      delivery_price: () => renderDeliveryPrice(order),
      additional_price: () => order.additional_price,
      totalPrice: () => order.totalPrice,
      billing_status: () => renderBillingStatus(order),
    },
  }));

  return (
    <div>
      <div className="invoice-list__actions">
        <span>
          <strong>{checkedOrders.length}</strong> orders selected
        </span>
        <span className="invoice-list__actions--buttons">
          <CSVLink
            data={csvOrders}
            headers={csvHeaders}
            filename="order-status-list.csv">
            <Button theme="small" onClick={() => {}}>
              <Icon icon="download" />
            </Button>
          </CSVLink>
          <Authorize
            abilityPermissions={[
              {
                resource: 'Reconcile',
                permissions: ['update'],
              },
            ]}>
            <DropdownButton
              theme="small"
              isDisabled={checkedOrders.length < 1}
              text="Actions"
              icon="down"
              options={actionOptions}
              noMargin
            />
          </Authorize>
          {/* TODO: Hidden until functionality is completed
          <ToolTip title="Export Selected..." placement="left">
            <DropdownButton
              onClick={() => {}}
              icon="export"
              className="button--small--square"
              options={exportOptions}
            />
          </ToolTip> */}
        </span>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <TableList
          onSort={handleSort}
          checkboxColumn
          clickableTable
          onCheck={handleCheck}
          columns={columns}
          listItems={builtItems}
          modifiers={['order-status']}
          reset={resetCheckboxes}
        />
      )}

      {!!orders?.length && (
        <Pagination
          currentPage={pagination.page}
          totalPages={orderData?.totalPages}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
          currentLimit={pagination.pageSize}
          withLimit
          isDisabled={isPlaceholderData}
        />
      )}

      <ReconcileSlidePanel
        isVisible={!!selectedOrder}
        closePanel={() => setSelectedOrder(null)}
        page="orderStatus"
        selectedOrder={selectedOrder}
        equipmentRequired={equipmentRequired}
      />
      <CreateInvoiceModal
        checkedOrders={checkedOrders}
        open={createInvoiceModal}
        onClose={() => setCreateInvoiceModal(false)}
        onSuccess={handleTableReset}
        orderIds={orderIds}
      />
      <ContestModal
        open={contestModal}
        onClose={() => setContestModal(false)}
        onSuccess={handleTableReset}
        orderIds={orderIds}
      />
    </div>
  );
};

export default ReconcileOrderStatusList;
