import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-switch';
import ToolTip from '../ToolTip';

import Icon from '../Icon';
import Button from '../Button';
import UIDatePicker from '../UIDatePicker';
import Select from '../Select';
import Input from '../Input';
import Label from '../Label';

/**
 * @typedef {{
 *  key: string;
 *  value: any;
 *  type: string;
 *  label: string;
 * }} Filter
 */

/**
 * @typedef {{
 *  activeFilters: Record<string, any>[];
 *  appliedFilters: Record<string, any>[];
 *  filterVisibility: boolean;
 * }} FilterState
 */

const FilterContext = React.createContext();

/**
 *
 * @param {FilterState} prevState
 * @param {*} action
 * @returns {FilterState}
 */
const FilterReducer = (prevState, action) => {
  switch (action.type) {
    case 'ADD_ACTIVE_FILTER':
      return {
        ...prevState,
        activeFilters: [...prevState.activeFilters, action.payload],
      };
    case 'SET_ACTIVE_FILTERS':
      return { ...prevState, activeFilters: action.payload };
    case 'SET_APPLIED_FILTERS':
      return { ...prevState, appliedFilters: action.payload };
    case 'SET_FILTER_VISIBILITY':
      return { ...prevState, filterVisibility: action.payload };
    default:
      return prevState;
  }
};
const Filter = ({
  children,
  onUpdate = () => {},
  active = [],
  manualUpdate = false,
  filterVisible = false,
}) => {
  const [state, dispatch] = React.useReducer(FilterReducer, {
    activeFilters: active,
    appliedFilters: { filters: [], trigger: false },
    filterVisibility: filterVisible,
  });

  const setAppliedFilters = ({ filters, trigger }) => {
    if (onUpdate && trigger) {
      onUpdate(filters);
    }
    dispatch({ type: 'SET_APPLIED_FILTERS', payload: { filters, trigger } });
  };

  const setActiveFilters = filters => {
    if (!manualUpdate) {
      setAppliedFilters({ filters, trigger: true });
    }
    dispatch({ type: 'SET_ACTIVE_FILTERS', payload: filters });
  };

  const setFilterVisibility = visibility => {
    dispatch({ type: 'SET_FILTER_VISIBILITY', payload: visibility });
  };

  useEffect(() => {
    setActiveFilters(active);

    if (!state.appliedFilters.trigger) {
      setAppliedFilters({ filters: active, trigger: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterActions = useMemo(
    () => ({
      /**
       * @param {Filter} newFilter
       */
      setFilter: newFilter => {
        const removedFilter = state.activeFilters.filter(
          filter => filter.key !== newFilter.key,
        );

        if (!(newFilter.value || newFilter.value === 0))
          return setActiveFilters(removedFilter);

        return setActiveFilters([...removedFilter, newFilter]);
      },
      removeFilter: filterKey => {
        const removedFilter = state.activeFilters.filter(
          filter => filter.key !== filterKey,
        );
        setActiveFilters(removedFilter);

        if (removedFilter.length > 0) {
          setAppliedFilters({ filters: removedFilter, trigger: true });
        } else {
          setAppliedFilters({ filters: [], trigger: false });
          onUpdate([]);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FilterContext.Provider value={{ ...state, ...filterActions }}>
      <div className="filters">
        <div className="filters__top-row">
          <span className="filters__active">
            Filters:
            {(state.appliedFilters.filters.length > 0 && (
              <>
                {state.appliedFilters.filters.map(({ key, label }) => (
                  <Label key={key} type="filter">
                    {label}
                    <Icon
                      icon="close"
                      onClick={() => filterActions.removeFilter(key)}
                    />
                  </Label>
                ))}
                <Button
                  theme="small"
                  onClick={() => {
                    setActiveFilters([]);
                    setAppliedFilters({ filters: [], trigger: false });
                    onUpdate([]);
                  }}>
                  Clear All
                </Button>
              </>
            )) || <strong> None</strong>}
          </span>

          <ToolTip title="Open/Close Filters" placement="left">
            <Button
              theme="small"
              className="filter-toggle button--small--square"
              onClick={() => setFilterVisibility(!state.filterVisibility)}
              inverse={state.filterVisibility}>
              <Icon icon="filter" />
            </Button>
          </ToolTip>
        </div>
        <div
          className={`filters__form-row ${
            state.filterVisibility ? 'filters__form-row--open' : ''
          }`}>
          <form
            className="filters__form"
            autoComplete="off"
            onSubmit={() => 'applyFilters'}>
            {children}
          </form>
          {manualUpdate && (
            <Button
              theme="small"
              onClick={() => {
                if (state.activeFilters.length > 0) {
                  setAppliedFilters({
                    filters: state.activeFilters,
                    trigger: true,
                  });
                } else {
                  setAppliedFilters({ filters: [], trigger: false });
                  onUpdate([]);
                }
              }}>
              Apply Filters
            </Button>
          )}
        </div>
      </div>
    </FilterContext.Provider>
  );
};

export const FilterInput = ({ filterKey, filterLabel = null, ...params }) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState('');

  const updateFilter = ({ target: { value } }) => {
    const typedValue = params.type === 'number' ? parseInt(value, 10) : value;
    return setFilter({
      key: filterKey,
      type: params.type,
      value: typedValue,
      label: filterLabel,
    });
  };

  useEffect(() => {
    if (activeFilters.find(item => item.key === filterKey)) {
      setValue(activeFilters.find(item => item.key === filterKey).value);
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <div
      className={`filters__item ${
        params.size ? `filters__item--${params.size}` : ''
      }`}>
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        value={currentValue}
        onChange={updateFilter}
        testSelector="filter_item_input"
      />
    </div>
  );
};

export const FilterSelect = ({
  filterKey,
  options,
  labelKey = 'label',
  valueKey = 'value',
  type = null,
  exact = false,
  filterLabel = null,
  ...params
}) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState([]);

  const updateFilter = value => {
    if (params.isMulti) {
      const typedValues = value.map(item =>
        type === 'number' ? parseInt(item.value, 10) : item.value,
      );
      let typeSelect = type;

      if (type === 'array') {
        typeSelect = exact ? 'arrayEvery' : 'arraySome';
      } else {
        typeSelect = exact ? 'multipleEvery' : 'multipleSome';
      }

      return setFilter({
        key: filterKey,
        type: typeSelect,
        value: value.length > 0 ? typedValues : null,
        label: filterLabel,
      });
    }

    return setFilter({
      key: filterKey,
      type,
      value: type === 'number' ? parseInt(value, 10) : value,
      label: filterLabel,
    });
  };

  const filteredOptions = options.map(item => ({
    value: item[valueKey],
    label: item[labelKey],
  }));

  useEffect(() => {
    if (activeFilters.find(item => item.key === filterKey)) {
      if (params.isMulti) {
        setValue(
          filteredOptions.filter(optionItems =>
            activeFilters
              .find(item => item.key === filterKey)
              .value.includes(optionItems.value),
          ),
        );
      } else {
        setValue(
          filteredOptions.find(
            optionItems =>
              activeFilters.find(item => item.key === filterKey).value ===
              optionItems.value,
          ),
        );
      }
    } else {
      setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  return (
    <div className="filters__item">
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        value={currentValue}
        options={filteredOptions}
        onChange={selection =>
          updateFilter(params.isMulti ? selection : selection.value)
        }
        testSelector="filter_current_value_select"
      />
    </div>
  );
};

export const FilterDatePicker = ({
  filterKey,
  filterLabel = null,
  range = null,
  isDateTimePicker = false,
  isTimePicker = false,
  timeFormat = true,
  isSimpleDatePicker = false,
  checkFormat = () => {},
  ...params
}) => {
  const { setFilter, activeFilters } = useContext(FilterContext);
  const [currentValue, setValue] = useState([]);

  useEffect(() => {
    if (range) {
      if (activeFilters.find(item => item.key === `${range}-${filterKey}`)) {
        setValue(
          activeFilters.find(item => item.key === `${range}-${filterKey}`)
            .value,
        );
      } else {
        setValue(null);
      }
    } else if (activeFilters.find(item => item.key === filterKey)) {
      setValue(activeFilters.find(item => item.key === filterKey).value);
    } else {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const updateFilter = value => {
    if (range) {
      return setFilter({
        key: `${range}-${filterKey}`,
        type: 'range',
        value,
        label: filterLabel,
      });
    }

    return setFilter({
      key: filterKey,
      type: 'date',
      value,
      label: filterLabel,
    });
  };

  return (
    <div
      className={`filters__item ${
        params.size ? `filters__item--${params.size}` : ''
      }`}>
      <UIDatePicker
        onAccept={item => {
          updateFilter(item);
          checkFormat(item);
        }}
        value={currentValue}
        data-testid="shared-filter_update_dp"
        id="shared-filter_update_dp"
        name="shared-filter_update_dp"
        isDateTimePicker={isDateTimePicker}
        isTimePicker={isTimePicker}
        timeFormat={timeFormat}
        isSimpleDatePicker={isSimpleDatePicker}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
      />
    </div>
  );
};

export const FilterToggle = ({ filterKey, filterLabel = null, ...params }) => {
  const [checked, setChecked] = useState(false);
  const { setFilter } = useContext(FilterContext);
  const updateFilter = value => {
    setChecked(value);
    return setFilter({
      key: filterKey,
      type: 'bool',
      value,
      label: filterLabel,
    });
  };

  return (
    <div className="filters__item">
      <label className="filter-toggle">
        <span className="filter-toggle__left-label">All</span>
        <Toggle
          checked={checked}
          onChange={updateFilter}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
          onColor="#6fc6d9"
          offColor="#5c5f65"
          height={20}
          width={40}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
        />
        <span className="filter-toggle__right-label">Demurrage</span>
      </label>
    </div>
  );
};

Filter.propTypes = {
  /** React children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.string,
  ]).isRequired,
  /** onUpdate function takes in (filterData)  */
  onUpdate: PropTypes.func,
  /** active array */
  active: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  ),
  /** manualUpdate boolean */
  manualUpdate: PropTypes.bool,
};

export default Filter;
