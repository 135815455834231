import swal from 'bootstrap-sweetalert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createInvoice } from './queries';

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createInvoice,
    onSuccess: () => {
      swal(
        'Successfully Requested Invoice',
        'Your invoice(s) are being created. You will be notified when they are complete.',
        'success',
      );
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
    },
    onError: error => {
      const errorMessage =
        error?.response?.data?.message || 'Something went wrong.';
      swal('Unable to Create Invoice', errorMessage, 'error');
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
  });
};
