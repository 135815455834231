const serverSideNaming = {
  orderId: 'orderId',
  jobId: 'jobId',
  customerId: 'customerId',
  carrierId: 'carrierId',
  districtIds: 'districtIds',
  notarizationStatus: 'notarizationStatus',
};

export const formatOrderFilters = filters => {
  const formattedFilters = filters.reduce((obj, { key, value }) => {
    const serverKey = serverSideNaming[key];

    switch (key) {
      case 'billing_status':
        obj[serverKey] = value.filter(item => item >= 20);
        obj.reviewStatuses = value.filter(item => item < 20);
        break;
      case 'demurrage':
        obj[serverKey] = value === 2 ? 0 : 1;
        break;
      default:
        obj[serverKey] = value;
    }

    if (obj.reviewStatuses?.length && !obj.billingStatuses?.length) {
      obj.billingStatuses = [0];
    }

    if (obj.orderId) {
      obj.billingStatuses = [];
      obj.reviewStatuses = [];
    }

    if (
      obj.jobId ||
      obj.carrierId ||
      obj.customerId ||
      obj.driverId ||
      obj.startDate ||
      obj.endDate
    ) {
      obj.billingStatuses = obj.billingStatuses || [];
    }

    if (obj.jobId?.length > 1) {
      obj.jobIds = obj.jobId;
      obj.jobId = null;
    }

    if (obj.carrierId?.length) {
      obj.carrierIds = obj.carrierId;
      obj.carrierId = null;
    }

    if (obj.billingStatuses?.length && !obj.billingStatuses.includes(0)) {
      obj.mergeBillingAndReviewFilters = true;
    }

    return obj;
  }, {});

  return formattedFilters;
};
